import {
  createEffect,
  onCleanup,
  untrack,
  Match,
  Switch,
  createMemo,
} from 'solid-js';
import { createMutable } from 'solid-js/store';
import { datadogRum } from '@datadog/browser-rum';
import { createQuery } from '@tanstack/solid-query';

import { PlayerDataContext } from '~/contexts/PlayerDataContext';

import {
  addLoadInterceptor,
  createPlayerData,
  PlayerState,
  broadcastCustomMessage,
  stopPlayback,
  bindPlayerData,
} from '~/cast/lib';
import { getPlaybackInfo } from '~/cast/VrioOtt';

import NpawMonitoring, { NpawMonitoringRef } from '~/components/NpawMonitoring';

import IdleScreen from '~/screens/Idle';
import LaunchingScreen from '~/screens/Launching';
import ErrorScreen from '~/screens/Error';
import ControlsScreen from '~/screens/Controls';
import BufferingScreen from '~/screens/Buffering';
import LoadingScreen from '~/screens/Loading';

import { userId, datadogUser } from '~/store/credentials';
import { appConfigQuery } from '~/store/tanstack-query';

import { errorFactory } from '~/utils/errorFactory';
import {
  Asset,
  checkBlackout,
  checkParentalControl,
  getHeartbeatContent,
  Schedule,
} from '~/utils/metadata';
import { CustomMessageType, NAMESPACE } from '~/utils/constants';
import { getErrorTexts } from '~/utils/messages';

import {
  CastUIState,
  createAssetChangeEffect,
  createErrorSignal,
  createGetStreamedTime,
  createHeartbeatController,
  createInitCastReceiverEffect,
  createUpdateErrorFactoryLocationEffect,
  useAutoplayNextEpisode,
  useUiState,
} from './utils';

export function App() {
  let npawMonitoringRef: NpawMonitoringRef | undefined;

  const appConfig = createQuery(() => appConfigQuery());
  const playerData = createMutable(createPlayerData());
  bindPlayerData(playerData);

  const playerState = () => playerData.state;
  const customData = createMemo(() => playerData.media?.customData);
  const isLive = () => customData()?.isLive ?? false;
  const asset = () => customData()?.asset;
  const assetId = () => asset()?.vrioAssetId;
  const progress = () => {
    if (playerData.isLive) {
      // eslint-disable-next-line prefer-destructuring -- destructuring causes conflict with solid/reactivity
      const liveSeekableRange = playerData.liveSeekableRange;
      return playerData.currentTime - (liveSeekableRange?.end ?? 0);
    }

    return playerData.currentTime;
  };
  const duration = () => playerData.duration;

  const [error, handleError] = createErrorSignal(playerState);
  const uiState = useUiState(playerState, error);
  const streamedTime = createGetStreamedTime({ assetId, playerState });

  const { startHeartbeat, stopHeartbeat } = createHeartbeatController(
    () =>
      appConfig.data && {
        heartbeatTimeInSeconds: appConfig.data.heartbeatTimeSeconds,
        useStreamingV4: appConfig.data.useStreamingV4,
      },
    { streamedTime, progress, duration, isLive, asset },
  );

  const { onAssetReceived, onFinished } = useAutoplayNextEpisode();

  const onPlaybackInfoAvailable = (
    options: { isLive: boolean; contentId: string },
    _: unknown,
    assetFromPlaybackInfo?: Asset | Schedule,
  ) => {
    startHeartbeat({
      playbackId: options.contentId,
      isLive: options.isLive,
      ...(assetFromPlaybackInfo && getHeartbeatContent(assetFromPlaybackInfo)),
    });

    if (!assetFromPlaybackInfo) return;

    if (options.isLive && 'live' in assetFromPlaybackInfo)
      checkBlackout(assetFromPlaybackInfo);

    if (appConfig.data?.parentalControl)
      checkParentalControl(assetFromPlaybackInfo);

    broadcastCustomMessage(NAMESPACE, {
      type: CustomMessageType.ASSET_LOAD,
      isLive: options.isLive,
      asset: assetFromPlaybackInfo,
    });

    onAssetReceived(assetFromPlaybackInfo, options.isLive);
  };

  createInitCastReceiverEffect(
    () => appConfig.data && { shakaVersion: appConfig.data.shakaVersion },
    { onShutdown: stopHeartbeat },
  );

  createEffect(() => {
    if (appConfig.data) {
      const cleanup = addLoadInterceptor(
        getPlaybackInfo(appConfig.data, {
          onPlaybackInfoAvailable,
          onAuthorizerError: (authorizerError) => {
            const vrioOttError =
              errorFactory.ensureVrioOttError(authorizerError);
            npawMonitoringRef?.reportError(
              vrioOttError,
              false,
              'onAuthorizerError',
            );
            handleError(vrioOttError);
          },

          onFinished: () => {
            onFinished();
            stopHeartbeat();
          },
        }),
      );

      onCleanup(cleanup);
    }
  });

  createAssetChangeEffect(assetId, () => {
    const currentAsset = untrack(asset);
    if (currentAsset) {
      try {
        if ('live' in currentAsset) checkBlackout(currentAsset);
        if (untrack(() => appConfig.data?.parentalControl))
          checkParentalControl(currentAsset);

        broadcastCustomMessage(NAMESPACE, {
          type: CustomMessageType.ASSET_LOAD,
          isLive: true,
          asset: currentAsset,
        });
      } catch (assetError) {
        const vrioOttError = errorFactory.ensureVrioOttError(assetError);
        npawMonitoringRef?.reportError(vrioOttError, true, 'assetChangeEffect');
        handleError(vrioOttError);
        stopPlayback();
      }
    }
  });

  createUpdateErrorFactoryLocationEffect(uiState);

  // Report the error to senders
  createEffect(() => {
    if (uiState() === CastUIState.ERROR) {
      const currentError = untrack(error);
      if (currentError) {
        const { title, message } = getErrorTexts(currentError);
        broadcastCustomMessage(NAMESPACE, {
          type: CustomMessageType.ERROR,
          error_data: {
            error_location: currentError.location,
            error_type: currentError.code,
            error_source: currentError.source,
            error_complement_code: currentError.externCode,
            error_vms: untrack(
              () => playerData.media?.customData?.asset?.vmsId,
            ),
            error_customer: untrack(userId),
            error_title: title,
            error_subtitle: message,
          },
        });
      }
    }
  });

  // When the system became ready, notify senders
  createEffect((prevState?: typeof playerData.state) => {
    if (
      prevState === PlayerState.LAUNCHING &&
      playerData.state === PlayerState.IDLE
    ) {
      broadcastCustomMessage(NAMESPACE, {
        type: CustomMessageType.CONNECTED,
        authorized_connection: true,
      });
    }
    return playerData.state;
  });

  createEffect(() => {
    const userData = datadogUser();
    if (userData) {
      datadogRum.setUser(userData);
    } else {
      datadogRum.clearUser();
    }
  });

  return (
    <PlayerDataContext.Provider value={playerData}>
      <NpawMonitoring ref={npawMonitoringRef} />
      <Switch>
        <Match when={uiState() === CastUIState.LAUNCHING}>
          <LaunchingScreen />
        </Match>
        <Match when={uiState() === CastUIState.ERROR}>
          <ErrorScreen error={error()!} />
        </Match>
        <Match when={uiState() === CastUIState.IDLE}>
          <IdleScreen />
        </Match>
        <Match when={uiState() === CastUIState.LOADING}>
          <LoadingScreen />
        </Match>
        <Match when={uiState() === CastUIState.BUFFERING}>
          <BufferingScreen />
        </Match>
        <Match when={uiState() === CastUIState.CONTROLS}>
          <ControlsScreen />
        </Match>
      </Switch>
    </PlayerDataContext.Provider>
  );
}
