import NpawPlugin from 'npaw-plugin';
import NpawAdapters from 'npaw-plugin-adapters';

import { parseCastError } from '../lib/utils';

import { CAFAdapterOptions } from './utils';

const OriginalNpawAdapterCAF = NpawAdapters.video
  .ChromecastCaf as new () => ReturnType<NpawPlugin['getAdapter']>;

export function getNpawAdapterCAF(options: CAFAdapterOptions) {
  return class NpawAdapterCAF extends OriginalNpawAdapterCAF {
    errorListener(event: framework.events.ErrorEvent) {
      if (event.error) {
        const { name, description, metadata, isFatal } = options.errorFormatter(
          parseCastError(event.error),
        );
        this.fireError({
          errorCode: name,
          msg: description,
          errorMetadata: metadata,
          triggeredEvents: ['errorListener'],
          errorType: isFatal ? 'fatal' : 'error',
        });
      }
    }
  };
}
