import { assetQuery, queryClient } from '~/store/tanstack-query';

import { getMediaMetadata } from './utils';

export async function updateVodMediaMetadata(contentId: string) {
  const asset = await queryClient.fetchQuery(assetQuery(contentId));

  const player =
    cast.framework.CastReceiverContext.getInstance().getPlayerManager();

  const mediaInformation =
    player.getMediaInformation() ??
    new cast.framework.messages.MediaInformation();

  if (!mediaInformation.customData) {
    mediaInformation.customData = {
      assetId: contentId,
      isLive: false,
      playSource: '',
      senderName: '',
      senderVersion: '',
    };
  }

  const { customData } = mediaInformation;
  customData.asset = asset;
  customData.isLive = false;

  const mediaMetadata = getMediaMetadata(asset);
  mediaInformation.metadata = mediaMetadata;

  player.setMediaInformation(mediaInformation);

  return asset;
}
